import httpCommon from '../../../shared/services/http-common'
import { HourlyCost } from '../interfaces/fees.interfaces'

class FeesServices {
    getFees() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/fees`)
    }

    updateFees(value: number) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/fees/update`, { value })
    }

    getHourlyCost() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/coutHoraire`)
    }

    updateHourlyCost(value: HourlyCost) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/coutHoraire`, { newData: value })
    }
}

const feesServices = new FeesServices()
export default feesServices
