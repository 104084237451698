import { Action } from 'redux'
import { Auth, LoginResponse } from '../interfaces/auth.interfaces'

export enum AuthActionTypes {
    GET_AUTH = '[Auth] get Auth',
    SET_TOKEN_INFO = '[Auth] get Token info',
    LOGOUT = '[Auth] logout user',
    SET_TEMP_EMAIL_RECOVER_ACCOUNT = '[Auth] set email Recovery Password'
}

/**
 * get Auth value
 */
export interface GetAuthAction extends Action<AuthActionTypes.GET_AUTH> {
    user: Auth
}

export const getAuthAction = (user: Auth): GetAuthAction => ({
    type: AuthActionTypes.GET_AUTH,
    user
})

/**
 * get Token Info
 */
export interface SetTokenInfoAction extends Action<AuthActionTypes.SET_TOKEN_INFO> {
    tokenInfo: LoginResponse
}

export const setTokenInfoAction = (tokenInfo: LoginResponse): SetTokenInfoAction => ({
    type: AuthActionTypes.SET_TOKEN_INFO,
    tokenInfo
})

/**
 *  Log Out User
 */
export interface LogOutAction extends Action<AuthActionTypes.LOGOUT> {}

export const logOutAction = (): LogOutAction => ({
    type: AuthActionTypes.LOGOUT
})

/**
 *  Store email for recovery password
 */
export interface SetEmailRecoverAccountAction extends Action<AuthActionTypes.SET_TEMP_EMAIL_RECOVER_ACCOUNT> {
    emailRecoveryAcc: Auth['email'] | null
}

export const setEmailRecoverAccountAction = (emailRecoveryAcc: Auth['email'] | null): SetEmailRecoverAccountAction => ({
    type: AuthActionTypes.SET_TEMP_EMAIL_RECOVER_ACCOUNT,
    emailRecoveryAcc
})

export type AuthActions = GetAuthAction | SetTokenInfoAction | LogOutAction | SetEmailRecoverAccountAction
