import httpCommon from '../../../shared/services/http-common'
import { Payment } from '../interfaces/payment.interface'

class PaymentServices {
    getAll(page: number, limit: number) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/payment/${page}/${limit}`)
    }

    getOne(id: Payment['_id']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/payment/get-one/${id}`)
    }
}

const paymentService = new PaymentServices()

export default paymentService
