import { connect } from 'react-redux'
import { AppState } from '../../../store/App.reducer'
import { getUtilisateurAction } from '../store/utilisateur.action'
import { selectUtilisateurPrestataire } from '../store/utilisateur.selector'
import UtilisateurPrestataireListComponent from '../components/utilisateur-prestataire-list.component'

type UtilisateurPrestataireListMappedProps = ReturnType<typeof mapStateToProps>
type UtilisateurPrestataireListDispatchedProps = typeof mapDisptachToProps

export type UtilisateurPrestataireInjectedProps = UtilisateurPrestataireListMappedProps & UtilisateurPrestataireListDispatchedProps

const mapStateToProps = (state: AppState) => ({
    prestataire: selectUtilisateurPrestataire(state)
})

const mapDisptachToProps = {
    loadUtilisateurPrestataire: getUtilisateurAction
}

const PrestataireListContainer = connect(mapStateToProps, mapDisptachToProps)(UtilisateurPrestataireListComponent)

export default PrestataireListContainer
