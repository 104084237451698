import { ApiResponse } from '../../../shared/interface/ApiResponse.interface'
import httpCommon from '../../../shared/services/http-common'
import { Domaine, DomaineCreateResponse, DomaineResponse } from '../interfaces/domaine.interface'

class DomaineServices {
    create(domaine: string): Promise<ApiResponse<DomaineCreateResponse>> {
        return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/domaine/create`, { name: domaine })
    }

    get(): Promise<ApiResponse<DomaineResponse>> {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/domaine`)
    }

    delete(id: Domaine['_id']): Promise<ApiResponse<DomaineResponse>> {
        return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/domaine/delete/${id}`)
    }

    update(id: Domaine['_id'], newName?: Domaine['name'], newMinimumRate?: Domaine['minimumRate']): Promise<ApiResponse<DomaineResponse>> {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/domaine/update/${id}`, { newName, newMinimumRate })
    }

    addNewEpi(id: Domaine['_id'], newEpi: string) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/domaine/new-epi/${id}`, { newEpi })
    }

    deleteAnEpi(id: Domaine['_id'], epi: string) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/domaine/delete-epi/${id}`, { epi })
    }

    updateAnEpi(id: Domaine['_id'], epiIndex: number, updatedEpi: string) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/domaine/update-epi/${id}`, { epiIndex, updatedEpi })
    }
}

const domaineServices = new DomaineServices()

export default domaineServices
