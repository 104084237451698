import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import userServices from '../../../pages/Utilisateur/services/utilisateur.service'
import { toast } from 'react-toastify'

export default function NewAdminModal(props: NewAdminModalProps) {
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isMailValid, setIsMailValid] = useState(false)
    const [saving, setSaving] = useState(false)

    const verifyMail = (mail: string): void => {
        const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
        const valid = mail !== '' && regex.test(mail)
        setIsMailValid(valid)
    }
    const handleClose = () => props.setOpen(false)

    const handleSave = () => {
        setSaving(true)
        userServices
            .createAdmin({
                firstname,
                lastname,
                email,
                password,
                passwordConfirm: confirmPassword
            })
            .then(() => toast.success('Compte administrateur ajouté avec succès'))
            .catch(console.log)
            .finally(() => {
                setSaving(false)
                props.setOpen(false)
                props.refresh()
            })
    }

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} sx={{ bottom: '40%', padding: 10, height: 700 }}>
                <DialogTitle textAlign="center" sx={{ fontSize: 15 }}>
                    <Typography variant="h5">Ajouter un admin</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon sx={{ fontSize: 25 }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="!p-4">
                    <div className="flex flex-col my-4 gap-3">
                        <FormControl>
                            <InputLabel htmlFor="firstname">Prénom</InputLabel>
                            <Input
                                id="firstname"
                                aria-describedby="my-helper-text"
                                value={firstname}
                                onChange={e => setFirstname(e.currentTarget.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor="lastname">Nom</InputLabel>
                            <Input
                                id="lastname"
                                aria-describedby="my-helper-text"
                                value={lastname}
                                onChange={e => setLastname(e.currentTarget.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor="email">Adresse e-mail</InputLabel>
                            <Input
                                id="email"
                                aria-describedby="my-helper-text"
                                value={email}
                                onChange={e => {
                                    const mail = e.currentTarget.value
                                    setEmail(mail)
                                    verifyMail(mail)
                                }}
                            />
                            {isMailValid || (
                                <FormHelperText id="my-helper-text">
                                    <span></span>E-mail invalide
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor="password">Mot de passe initial</InputLabel>
                            <Input
                                id="password"
                                aria-describedby="my-helper-text"
                                value={password}
                                onChange={e => setPassword(e.currentTarget.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor="confirmation">Confirmation mot de passe</InputLabel>
                            <Input
                                id="confirmation"
                                aria-describedby="my-helper-text"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.currentTarget.value)}
                            />
                        </FormControl>
                    </div>
                    <div className="flex justify-between">
                        <Box sx={{ marginBottom: 4 }}>
                            <Grid container justifyContent="center" alignItems="center" gap={1}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        className="!w-[200px]"
                                        color="success"
                                        onClick={handleSave}
                                        disabled={
                                            firstname === '' || lastname === '' || !isMailValid || password === '' || confirmPassword === ''
                                        }
                                    >
                                        Confirmer
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" className="!w-[200px]" color="error" onClick={handleClose}>
                                        Annuler
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

type NewAdminModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
    refresh: () => void
}
