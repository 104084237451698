import httpCommon from '../../../shared/services/http-common'
import { Notification } from '../interfaces/notification.interface'

class NotificationServices {
    getAll(page: number, limit: number) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/proposition/${page}/${limit}`, { headers: { activity: 'pendingCreator' } })
    }

    postMission(data: any) {
        return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/proposition/create`, data)
    }

    getById(id: Notification['_id']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/proposition/get-one/${id}`)
    }

    delete(id: Notification['_id']) {
        return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/proposition/${id}`)
    }
}

const notificationService = new NotificationServices()

export default notificationService
