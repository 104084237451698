import { Mission } from '../../Missions/interfaces/missions.interface'
import { User } from '../../Utilisateur/interfaces/utilisateur.interface'

export interface Notification {
    _id: string
    mission: Mission
    state: string
    prestataire: User
    createur: User
    note: number
    createdAt: Date
    [key: string]: any
}

export enum NotificationStatus {
    'PENDING' = 'pending',
    'ACCEPTED' = 'accepted',
    'COMMENCE' = 'begin',
    'REFUSED' = 'refused',
    'TERMINE' = 'ended',
    'PAYE' = 'paid',
    'FACTURED' = 'factured'
}
