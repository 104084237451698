import { connect } from 'react-redux'
import { AppState } from '../../../store/App.reducer'
import DomaineComponent from '../components/domaine.component'
import { setDomaineAction } from '../store/domaine.action'
import { selectDomaine } from '../store/domaine.selector'

type DomaineMappedProps = ReturnType<typeof mapStateToProps>
type DomaineDispatchedProps = typeof mapDisptachToProps

export type DomaineInjectedProps = DomaineMappedProps & DomaineDispatchedProps

const mapStateToProps = (state: AppState) => ({
    domaines: selectDomaine(state)
})

const mapDisptachToProps = {
    loadDomaines: setDomaineAction
}

const DomaineContainer = connect(mapStateToProps, mapDisptachToProps)(DomaineComponent)

export default DomaineContainer
