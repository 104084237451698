import { VisibilityOff, Visibility } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'

import { toast } from 'react-toastify'
import { BASE_COLOR } from '../../../shared/constants/color.constant'

const EditPasswordComponent: React.FC<any> = () => {
    // const navigate = useNavigate()
    // const disptach = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [passwordValidator, setPasswordValidator] = useState<boolean>(true)
    const [confirmPasswordValidator, setConfirmPasswordValidator] = useState<boolean>(true)
    const handleClickShowPassword = () => setShowPassword(show => !show)
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show)
    const handleClickShowOldPassword = () => setShowOldPassword(show => !show)
    const passwordValidationRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}$/

    const passwordIsValide = password.length >= 8
    const passwordConfirmIsValide = confirmPassword.length >= 8

    const [oldPassword, setOldPassword] = useState<string>('')
    const [oldPasswordValidator, setOldPasswordValidator] = useState<boolean>(true)

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }
    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleMouseDownOldPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const checkStrongPassword = (password: string): boolean => {
        return passwordValidationRegex.test(password)
    }

    const checkStrongPasswordMessage = (password: string, validator: boolean): string => {
        if (password.length < 8 && !validator) return '8 caractères minimum'
        if (password.length > 0) return passwordValidationRegex.test(password) ? 'le mot de passe est fort' : 'le mot de passe est faible'
        return ''
    }

    const colorTextHelperMessage = (password: string, validator: boolean): any => {
        let style = {
            helper: {
                color: 'red'
            }
        }
        if (password.length < 8 && !validator) {
            return style
        } else if (passwordValidationRegex.test(password)) {
            style.helper.color = '#2e7d32'
        } else {
            style.helper.color = '#ed6c02'
        }
        return style
    }

    const loginNewPassword = () => {
        if (!oldPassword.length) setOldPasswordValidator(false)
        if (!passwordIsValide) setPasswordValidator(false)
        if (!passwordConfirmIsValide) setConfirmPasswordValidator(false)

        if (password === confirmPassword && passwordIsValide && passwordConfirmIsValide && oldPassword.length > 0) {
            setOldPasswordValidator(true)
            setPasswordValidator(true)
            setConfirmPasswordValidator(true)
            // if (!email) {
            //     toast.warning('Veuiller identifer votre email')
            //     navigate('/mot-de-passe-oublier')
            // } else {
            // const params: resetPassword = {
            //     recoveryCode: code,
            //     password: password,
            //     password_confirm: confirmPassword
            // }
            // authServices
            //     .resetPassword(params)
            //     .then((response: any) => {
            //         if (response.data.success) {
            // disptach(setEmailRecoverAccountAction(null))
            // disptach(setTokenInfoAction(response.data))
            // disptach(getAuthAction(response.data.user))

            toast.success('Modification mot de passe réussie')
            //     } else {
            //         toast.error(`${response.data.message}`)
            //     }
            // })
            // .catch(error => {
            //     toast.error(error.message)
            // })
        } else {
            if (password !== confirmPassword) toast.error('Les mots de passe doivent être identiques')
            if (!oldPassword.length) toast.error('Mot de passe actuel est nécessaire')
        }
    }

    return (
        <div className="flex flex-col  items-center gap-16">
            <div className="bg-white rounded-lg">
                <div className="mx-[45px] my-[32px] flex flex-col gap-3">
                    <TextField
                        label={`Ancien Mot de passe`}
                        error={!oldPasswordValidator}
                        color={'primary'}
                        id="outlined-adornment-password"
                        type={showOldPassword ? 'text' : 'password'}
                        onChange={(e: any) => {
                            setOldPassword(e.target.value)
                        }}
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        // helperText={!oldPasswordValidator ? 'mote' : ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowOldPassword}
                                        onMouseDown={handleMouseDownOldPassword}
                                        edge="end"
                                    >
                                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        label={`Nouveau Mot de passe`}
                        error={!passwordValidator}
                        color={password.length ? (checkStrongPassword(password) ? 'success' : 'warning') : 'primary'}
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e: any) => {
                            setPassword(e.target.value)
                        }}
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        helperText={checkStrongPasswordMessage(password, passwordValidator)}
                        FormHelperTextProps={{ style: colorTextHelperMessage(password, passwordValidator)?.helper }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        label={`Confirmation mot de passe`}
                        error={!confirmPasswordValidator}
                        color={confirmPassword.length ? (checkStrongPassword(confirmPassword) ? 'success' : 'warning') : 'primary'}
                        id="outlined-adornment-confirm-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        onChange={(e: any) => {
                            setConfirmPassword(e.target.value)
                        }}
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        helperText={checkStrongPasswordMessage(confirmPassword, confirmPasswordValidator)}
                        FormHelperTextProps={{ style: colorTextHelperMessage(confirmPassword, confirmPasswordValidator)?.helper }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownConfirmPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <div className="flex justify-center w-full">
                        <Button
                            variant="contained"
                            sx={{ borderRadius: '20px', backgroundColor: BASE_COLOR.PRIMARY }}
                            onClick={loginNewPassword}
                            className="w-2/3"
                        >
                            Enregistrer
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPasswordComponent
