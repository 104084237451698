import httpCommon from '../../../shared/services/http-common'
import { Domaine } from '../../Domaine/interfaces/domaine.interface'
import { CreateAdminDto, User, UserRole } from '../interfaces/utilisateur.interface'

class UserServices {
    getAll(page: number) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users?page=${page}`)
    }

    getById(userId: User['_id']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/one/${userId}`)
    }
    getByEmail(userEmail: User['email']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/${userEmail}`)
    }

    getByRole(page: number, roleId: UserRole['_id'], limit: number, name?: string, domaineId?: Domaine['_id'], query?: any): Promise<any> {
        let url: string[] = []
        if (name) url.push(`name=${name}`)
        if (domaineId) url.push(`domaineId=${domaineId}`)
        if (query) {
            for (const key of Object.keys(query)) {
                if (query[key] && query[key] !== '') {
                    url.push(`${key}=${query[key]}`)
                }
            }
        }
        return httpCommon.get(
            `${process.env.REACT_APP_API_ROOT}/users/by-role/${roleId}/${page}/${limit}${url.length > 0 ? '?' + url.join('&') : ''}`
        )
    }

    deleteAUser(userId: User['_id']) {
        return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/users/${userId}`)
    }

    getRoles(): Promise<any> {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/roles`)
    }

    createAdmin(params: CreateAdminDto): Promise<any> {
        return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/users/create-admin`, params)
    }

    getAllAdmin(): Promise<any> {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/getall-admin`)
    }
}

const userServices = new UserServices()

export default userServices
