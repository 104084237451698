import styled from '@emotion/styled'
import {
    TableRow,
    tableCellClasses,
    TableCell,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    Backdrop,
    CircularProgress
} from '@mui/material'
import { useEffect, useState } from 'react'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import userServices from '../services/utilisateur.service'
import { RolesType } from '../../../shared/constants/roleType'
// import DeleteIcon from '@mui/icons-material/Delete'
// import VisibilityIcon from '@mui/icons-material/Visibility'
// import EditIcon from '@mui/icons-material/Edit'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: BASE_COLOR.WHITE,
        border: 0
    },
    '&:last-child td, &:last-child th': {
        border: 0
    },
    [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none'
    }
}))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: BASE_COLOR.CONTAINER,
        // fontSize: 15,
        fontWeight: 'bold'
        // textTransform: 'uppercase'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15
    }
}))

const UtilisateursRolesComponent: React.FC<any> = () => {
    const [roles, setRoles] = useState<any[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        userServices
            .getRoles()
            .then(res => setRoles(res.data.roles))
            .catch(console.log)
            .finally(() => setLoading(false))
    }, [])
    return (
        <>
            <TableContainer component="div" sx={{ maxHeight: 440 }}>
                <Table
                    stickyHeader
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none'
                        }
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableHeader>Nom </StyledTableHeader>

                            <StyledTableHeader>Action</StyledTableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow key={0}>
                            <TableCell>{roles.find(elt => elt.type === RolesType.ADMIN)?.type}</TableCell>
                            <TableCell>Gestion des données de la plateforme</TableCell>
                        </StyledTableRow>
                        <StyledTableRow key={1}>
                            <TableCell>{roles.find(elt => elt.type === RolesType.SERVICE_PROVIDER)?.type}</TableCell>
                            <TableCell>Réponse aux différentes missions proposées dans la plateforme.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow key={2}>
                            <TableCell>{roles.find(elt => elt.type === RolesType.CREATOR)?.type}</TableCell>
                            <TableCell>Emission des différentes missions disponibles.</TableCell>
                        </StyledTableRow>
                        <StyledTableRow key={2}>
                            <TableCell>{roles.find(elt => elt.type === RolesType.MULTI)?.type}</TableCell>
                            <TableCell>Possession de plusieurs sous-comptes créateurs de missions.</TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </>
    )
}

export default UtilisateursRolesComponent
