import axios from 'axios'
import { store } from '../../store/App.store'
import { toast } from 'react-toastify'

const requestArray: any[] = []

const httpCommon = axios.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}`,
    headers: {
        'Content-type': 'application/json',
        'X-Referer': window.location.href
    }
})

httpCommon.interceptors.request.use(async config => {
    const token = store.getState().auth.tokenInfo?.token
    config.headers = { authorization: `Bearer ${token}` } as any
    return config
})

httpCommon.interceptors.response.use(
    async response => {
        if (requestArray.length !== 0) {
            requestArray.forEach(function (x, i) {
                if (response.config.url === x.url) {
                    requestArray.splice(i, 1)
                }
            })
        }
        return response
    },
    async error => {
        const originalConfig = error.config
        requestArray.push(originalConfig)
        if (!error.response) {
            toast.error(`Erreur API (Code: non défini)`)
        }

        if (error.response.status === 400) {
            const responseData = error.response.data
            if (responseData.message) {
                toast.error(responseData.message)
            }
            if (responseData.errors?.messages && responseData.errors?.messages.length) {
                toast.error(responseData.errors?.messages)
            }
        }

        if (error.response.status === 403) {
            if (!error.response.data?.message?.includes('reset')) {
                toast.error("Accès refusé. Vous n'avez pas l'autorisation nécessaire pour accéder à cette page")
            }
        }

        if (error.response.status === 404) {
            if (error.response.data?.message) {
                toast.error(error.response.data.message)
            }
        }

        if (error.response.status === 500 || error.response.status === 503) {
            toast.error(`Erreur API (Code: ${error.response.status}, Message: ${error.response.data.message})`)
        }

        return Promise.reject(error)
    }
)

export default httpCommon
