import { Auth, LoginResponse } from '../interfaces/auth.interfaces'
import { AuthActions, AuthActionTypes } from './auth.action'

interface AuthState {
    user: Auth | null
    tokenInfo: LoginResponse | null
    userLoading: boolean
    emailRecoveryAcc: string | null
}

const initialState: AuthState = {
    user: null,
    tokenInfo: null,
    userLoading: false,
    emailRecoveryAcc: null
}

const authReducer = (state: AuthState = initialState, action: AuthActions): AuthState => {
    switch (action.type) {
        case AuthActionTypes.GET_AUTH:
            return {
                ...state,
                user: action.user
            }
        case AuthActionTypes.SET_TOKEN_INFO:
            return {
                ...state,
                tokenInfo: action.tokenInfo
            }
        case AuthActionTypes.LOGOUT:
            return {
                ...state,
                tokenInfo: null,
                user: null
            }
        case AuthActionTypes.SET_TEMP_EMAIL_RECOVER_ACCOUNT:
            return {
                ...state,
                emailRecoveryAcc: action.emailRecoveryAcc
            }
        default:
            return state
    }
}

export default authReducer
