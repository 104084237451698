import httpCommon from '../../../shared/services/http-common'
import { Domaine } from '../../Domaine/interfaces/domaine.interface'
import { IPosteOccupe } from '../interfaces/post.interface'

class PostServices {
    getPostsByDomaine(domaineId: Domaine['_id']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/entreprise/getPosteByDomaine/${domaineId}`)
    }

    getAllPosts() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/entreprise/getPosteOccupe`)
    }

    create(name: string, domaine: Domaine['_id']) {
        return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/postes/create`, { name, domaine })
    }

    update(name: string, posteId: IPosteOccupe['_id']) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/postes/update/${posteId}`, { newName: name })
    }

    delete(posteId: IPosteOccupe['_id']) {
        return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/postes/delete/${posteId}`)
    }
}

const postServices = new PostServices()
export default postServices
