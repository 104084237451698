import httpCommon from '../../../shared/services/http-common'

class CounterServices {
    getAll() {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/compteur`)
    }
}

const counterServices = new CounterServices()

export default counterServices
