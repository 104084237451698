import { Typography } from '@mui/material'
import CounterCard from './counter-card.component'
import { useEffect, useState } from 'react'
import counterServices from '../services/counter.service'
import { io } from 'socket.io-client'
import { CounterResponse } from '../interfaces/counter.interface'
const socket = io(`${process.env.REACT_APP_API_ROOT}`)

const CounterDisplayComponent: React.FC<any> = () => {
    const [results, setResults] = useState<CounterResponse | null>(null)
    const [loading, setLoading] = useState(false)

    const fetchAllCounters = () => {
        setLoading(true)
        counterServices
            .getAll()
            .then((res: any) => setResults(res.data.data as CounterResponse))
            .catch(console.log)
            .finally(() => setLoading(false))
    }
    useEffect(() => {
        socket.on('receive', _ => {
            fetchAllCounters()
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchAllCounters()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        socket.on('receive', _ => {
            fetchAllCounters()
        })
        // eslint-disable-next-line
    }, [])

    if (!results || loading) return <></>

    return (
        <div className="p-4">
            <div className="my-4">
                <div className="my-2">
                    <Typography variant="h6">Nombre total de missions réalisées</Typography>
                </div>
                <div className="w-1/4">
                    <CounterCard label="Missions réalisées" count={results.missionsRealises} />
                </div>
            </div>
            <div className="my-4">
                <div className="my-2">
                    <Typography variant="h6">Compteur par groupement horaire</Typography>
                </div>
                <div className="grid grid-cols-4 gap-5 w-full">
                    <CounterCard label="Missions d'une heure" count={results.missionUneHeure} />
                    <CounterCard label="Missions de deux heures" count={results.missionDeuxHeures} />
                    <CounterCard label="Missions d'une demi-journée" count={results?.missionQuatreHeures} />
                    <CounterCard label="Missions d'une journée" count={results?.missionHuitHeures} />
                </div>
            </div>
            <div className="my-4">
                <div className="my-2">
                    <Typography variant="h6">Missions non-pourvues</Typography>
                </div>
                <div className="w-1/4">
                    <CounterCard label="Non-pourvues" count={results.missionNonPourvue} />
                </div>
            </div>
            <div className="my-4">
                <div className="my-2">
                    <Typography variant="h6">Total des notifications envoyées</Typography>
                </div>
                <div className="w-1/4">
                    <CounterCard label="Notifications envoyées" count={results.notifEnvoye} />
                </div>
            </div>
        </div>
    )
}

export default CounterDisplayComponent
