import { ApiResponse } from '../../../shared/interface/ApiResponse.interface'
// import httpCommon from '../../../shared/services/http-common'
import axios from 'axios'
import { AxiosRequestConfig } from 'axios'
import { HeadersStats, Response, StatistiqueGeneraleResponse } from '../interfaces'

class StatistiqueServices {
    getStatistiqueGenerale(customHeaders: HeadersStats): Promise<ApiResponse<Response<StatistiqueGeneraleResponse[]>>> {
        // Flatten customHeaders
        const flattenedHeaders: any = {
            'Content-Type': 'application/json', // Replace with your actual authorization token
            colorVert:JSON.stringify(customHeaders.colorVert),
            colorJaune:JSON.stringify(customHeaders.colorJaune),
            colorRouge:JSON.stringify(customHeaders.colorRouge),
            beginDate: customHeaders.beginDate?.toISOString() || '',
            endDate: customHeaders.endDate?.toISOString() || ''
        }

        // Explicitly cast to AxiosHeaders
        const headers: AxiosRequestConfig['headers'] = flattenedHeaders as AxiosRequestConfig['headers']
        console.log("headers ****",headers)

        return axios.get(`${process.env.REACT_APP_API_ROOT}/statistiqueGeneral`,{headers})
    }
}

const statistiqueServices = new StatistiqueServices()
export default statistiqueServices
