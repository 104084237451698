import { Card, CardContent, CardHeader, Typography } from '@mui/material'

const CounterCard: React.FC<CounterCardProps> = props => {
    return (
        <div className="w-full h-full">
            <Card className="!bg-slate-100 drop-shadow-lg !h-full">
                <CardHeader title={<Typography variant="h6">{props.label}</Typography>} />
                <CardContent>
                    <Typography variant="h6" color="blue">
                        {props.count}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}

type CounterCardProps = {
    label: string
    count: number
}

export default CounterCard
