import { Box, Typography } from '@mui/material'
import { BASE_COLOR } from '../../constants/color.constant'

export default function PagenotFoundComponent() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '85vh',
                backgroundColor: BASE_COLOR.PRIMARY
            }}
            className="w-full md:w-[80vw]"
        >
            <Typography variant="h1" style={{ color: 'white' }}>
                404
            </Typography>
            <Typography variant="h6" style={{ color: 'white' }}>
                La page que vous recherchez n'existe pas.
            </Typography>
        </Box>
    )
}
