import { ApiResponse, ResponseMessage } from '../../../shared/interface/ApiResponse.interface'
import httpCommon from '../../../shared/services/http-common'
import { Login, LoginResponse, resetPassword } from '../interfaces/auth.interfaces'

class AuthServices {
    login(params: Login): Promise<ApiResponse<LoginResponse>> {
        return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/users/login/admin`, params)
    }

    sendEmailResetPassword(email: Login['email']): Promise<ApiResponse<ResponseMessage>> {
        return httpCommon.post(`${process.env.REACT_APP_API_ROOT}/users/send-mail`, { email })
    }

    resetPassword(params: resetPassword): Promise<ApiResponse<LoginResponse>> {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/users/reset-password`, params)
    }
}

const authServices = new AuthServices()

export default authServices
