import { Provider } from 'react-redux'
import AppRouter from './routes/Routes'
import { store, persistedStore } from './store/App.store'
import { PersistGate } from 'redux-persist/integration/react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { frFR } from '@mui/x-date-pickers'
import 'dayjs/locale/fr'

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistedStore}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr"
                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
                    >
                        <AppRouter />
                    </LocalizationProvider>
                </PersistGate>
            </Provider>
        </div>
    )
}

export default App
