import { Button, TextField } from '@mui/material'
import { useState } from 'react'
import monImage from '../../../shared/images/logo.svg'
import { useDispatch } from 'react-redux'
import { setEmailRecoverAccountAction } from '../store/auth.action'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import authServices from '../services/auth.service'
import { ApiResponse, ResponseMessage } from '../../../shared/interface/ApiResponse.interface'

const ResetPasswordComponent: React.FC<any> = () => {
    const navigate = useNavigate()
    const disptach = useDispatch()
    const [email, setEmail] = useState<string>('')
    const emailRegex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const [emailValidator, setEmailValidator] = useState<boolean | null>(true)

    const nextStep = () => {
        if (emailRegex.test(email)) {
            setEmailValidator(true)
            if (emailRegex.test(email)) {
                setEmailValidator(true)
                authServices
                    .sendEmailResetPassword(email)
                    .then((response: ApiResponse<ResponseMessage>) => {
                        if (response.data.success === true) {
                            disptach(setEmailRecoverAccountAction(email))
                            navigate('/noveau-mot-de-passe')
                            toast.success('Veuillez consulter votre Boîte Email')
                        } else {
                            console.log(response.data.message)
                            toast.error(response.data.message)
                        }
                    })
                    .catch(error => toast.error(error))
            } else {
                setEmailValidator(false)
            }
        } else {
            setEmailValidator(false)
        }
    }

    return (
        <div className="w-screen h-screen bg-primary flex flex-col justify-center items-center gap-16">
            <div className="bg-white rounded-[64px] p-5 h-44 w-44 bg-opacity-0">
                <img src={monImage} alt="logo" className=" h-40" />
            </div>
            <div className="bg-white rounded-lg">
                <div className="mx-[45px] my-[32px] flex flex-col gap-3">
                    <TextField
                        label={`Votre email`}
                        error={!emailValidator}
                        onChange={(e: any) => {
                            setEmail(e.target.value.trim())
                        }}
                        id="outlined-start-adornment"
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        helperText={!emailValidator ? 'Email incorrect' : ''}
                    />
                    <div className="flex justify-center w-full">
                        <Button variant="contained" sx={{ borderRadius: '20px' }} onClick={nextStep} className="w-2/3 !bg-[#0B7D75]">
                            Valider
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordComponent
