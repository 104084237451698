import { Mission } from '../interfaces/missions.interface'
import { useEffect, useState } from 'react'
import MissionCard from './mission-card.component'
import { Backdrop, Box, CircularProgress, FormControl, Grid, MenuItem, Pagination, Select, Stack, Typography } from '@mui/material'
import missionService from '../services/missions.service'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import userServices from '../../Utilisateur/services/utilisateur.service'
import ModaleValidationComponent from '../../../shared/components/Modale/modale-validation.component'
import { toast } from 'react-toastify'

const MissionsListComponent: React.FC<any> = () => {
    const [missions, setMissions] = useState<Mission[]>([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [open, setOpen] = useState(false)
    const [selectedMission, setSelectedMission] = useState<Mission | null>(null)
    const [reload, setReload] = useState(0)
    const [totalPage, setTotalPage] = useState(0)

    const handleChangelimit = (value: number) => setLimit(value)

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage)
    }

    const deleteMission = (mission: Mission) => {
        missionService
            .deleteOne(mission._id)
            .then(() => {
                toast.success('Mission supprimée avec succès')
                setReload(prev => ++prev)
            })
            .catch(console.log)
            .finally(() => {
                setOpen(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        missionService
            .getAll(page, limit)
            .then(res => {
                setMissions(res.data.data as Mission[])
                setTotalPage(+res.data.totalPage)
            })
            .catch(console.log)
            .finally(() => setLoading(false))
    }, [page, reload, limit])

    return (
        <>
            {/* <Grid container alignItems="center" paddingY={2} spacing={1} gap={1}>
                <Grid item xs={12} md={3}>
                    <Input placeholder="Nom" fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        {...defaulProps}
                        id="test"
                        clearOnEscape
                        renderInput={params => <TextField {...params} label="Domaine" variant="standard" sx={{ mb: 2 }} />}
                    />
                </Grid>
            </Grid> */}
            {missions.length > 0 ? (
                <div className="flex flex-col sm:grid sm:grid-cols-4 gap-4">
                    {missions.map(mission => (
                        <MissionCard
                            mission={mission}
                            title={mission.domaine?.name}
                            onDelete={() => {
                                setOpen(true)
                                setSelectedMission(mission)
                            }}
                        />
                    ))}
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-screen h-screen">
                    <Typography>Aucune mission enregistrée pour le moment.</Typography>
                    <SentimentVeryDissatisfiedIcon />
                </div>
            )}
            <Box sx={{ display: 'flex', mt: 5 }}>
                <Grid container alignItems="center" justifyContent="end" gap={1}>
                    <Stack spacing={2}>
                        <Pagination
                            showFirstButton={page === 1}
                            showLastButton={page === Math.ceil(missions.length / limit)}
                            count={totalPage}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                            shape="rounded"
                        />
                    </Stack>
                    <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                        <Select
                            value={limit}
                            onChange={e => handleChangelimit(+e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={5}>5 / page</MenuItem>
                            <MenuItem value={10}>10 / page</MenuItem>
                            <MenuItem value={25}>25 / page</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Box>
            <ModaleValidationComponent
                openModale={open}
                setOpen={setOpen}
                message={`Voulez-vous supprimer la mission ?`}
                handleValidate={() => {
                    if (selectedMission) deleteMission(selectedMission)
                }}
            />
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </>
    )
}

export default MissionsListComponent
