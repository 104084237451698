import { Action } from 'redux'
import { User } from '../interfaces/utilisateur.interface'

export enum UtilisateurActionTypes {
    GET_UTILISATEUR_PRESTATAIRE = '[User] get User Prestataire list'
}

/**
 * get User prestataire value
 */
export interface GetPrestataireAction extends Action<UtilisateurActionTypes.GET_UTILISATEUR_PRESTATAIRE> {
    prestataire: User[]
}

export const getUtilisateurAction = (prestataire: User[]): GetPrestataireAction => ({
    type: UtilisateurActionTypes.GET_UTILISATEUR_PRESTATAIRE,
    prestataire
})

export type UtilisateurActions = GetPrestataireAction
