import {
    Backdrop,
    Box,
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    styled,
    tableCellClasses
} from '@mui/material'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import { useEffect, useState } from 'react'
import { User } from '../../Utilisateur/interfaces/utilisateur.interface'
import { DateFormater } from '../../../shared/functions/date'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import ModaleValidationComponent from '../../../shared/components/Modale/modale-validation.component'
import accountBlockingServices from '../services/account-blocking.service'
import ModaleValidationDeblocageComponent from '../../../shared/components/Modale/modale-validation-deblocage.component'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: BASE_COLOR.CONTAINER,
        border: 0
    },
    [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none'
    }
}))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: BASE_COLOR.CONTAINER,
        // fontSize: 15,
        fontWeight: 'bold'
        // textTransform: 'uppercase'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15
    }
}))

const AccountBlockingComponent: React.FC = () => {
    const [users, setUsers] = useState<User[]>([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [reload, setReload] = useState(0)

    useEffect(() => {
        setLoading(true)
        accountBlockingServices
            .getAllblockedUserAccount(page, rowsPerPage)
            .then(res => setUsers(res.data.data))
            .catch(console.log)
            .finally(() => setLoading(false))
    }, [page, rowsPerPage, reload])

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const [selectedItem, setSelectedItem] = useState<User | null>(null)

    const liftUserSuspension = () => {
        if (selectedItem)
            accountBlockingServices
                .liftSuspension(selectedItem._id)
                .then(() => {
                    setOpen(false)
                    setSelectedItem(null)
                    setReload(prev => ++prev)
                })
                .catch(console.log)
    }

    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState<string>('fullName')

    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        const newOrder = isAsc ? 'desc' : 'asc'
        setOrder(newOrder)
        setOrderBy(property)

        const sorted = [...users].sort((a, b) => (newOrder === 'asc' ? handleSortBy(a, b, property) : handleSortBy(b, a, property)))

        setUsers(sorted)
    }

    const handleSortBy = (a: User, b: User, property: string) => {
        switch (property) {
            case 'fullName':
                return `${a['lastname']} ${a['firstname']}`.localeCompare(`${b['lastname']} ${b['firstname']}`)
            default:
                return (a[property] ?? '').localeCompare(b[property])
        }
    }

    return (
        <>
            <TableContainer component="div" sx={{ maxHeight: 440, maxWidth: 1200, width: '100%', display: 'block' }}>
                <Table
                    stickyHeader
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none'
                        }
                    }}
                    className="border table-auto mx-9"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableHeader sortDirection={orderBy === 'fullName' ? order : false} sx={{ minWidth: 150 }}>
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === 'fullName' ? order : 'asc'}
                                    onClick={() => handleSort('fullName')}
                                >
                                    Nom et prénom
                                </TableSortLabel>
                            </StyledTableHeader>
                            <StyledTableHeader sortDirection={orderBy === 'email' ? order : false} sx={{ minWidth: 150 }}>
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === 'email' ? order : 'asc'}
                                    onClick={() => handleSort('email')}
                                >
                                    Email
                                </TableSortLabel>
                            </StyledTableHeader>
                            <StyledTableHeader sortDirection={orderBy === 'numero' ? order : false} sx={{ minWidth: 150 }}>
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === 'numero' ? order : 'asc'}
                                    onClick={() => handleSort('numero')}
                                >
                                    Téléphone
                                </TableSortLabel>
                            </StyledTableHeader>
                            <StyledTableHeader sortDirection={orderBy === 'mission' ? order : false} sx={{ minWidth: 150 }}>
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === 'mission' ? order : 'asc'}
                                    onClick={() => handleSort('mission')}
                                >
                                    Annulation de mission
                                </TableSortLabel>
                            </StyledTableHeader>
                            <StyledTableHeader sortDirection={orderBy === 'createdAt' ? order : false} sx={{ minWidth: 150 }}>
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === 'createdAt' ? order : 'asc'}
                                    onClick={() => handleSort('createdAt')}
                                >
                                    Créé le
                                </TableSortLabel>
                            </StyledTableHeader>
                            <StyledTableHeader sx={{ minWidth: 150 }}>Actions</StyledTableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((row: User, i: number) => (
                            <StyledTableRow key={i}>
                                <TableCell>
                                    <div className="whitespace-nowrap">{`${row.firstname} ${row.lastname}`}</div>
                                </TableCell>
                                <TableCell className="border">{row.email}</TableCell>
                                <TableCell className="border">{row.numero}</TableCell>
                                <TableCell className="border">{row.accountWarning}</TableCell>
                                <TableCell className="border">{DateFormater(row.createdAt.toString())}</TableCell>
                                <TableCell className="border">
                                    <LockOpenIcon
                                        onClick={() => {
                                            setSelectedItem(row)
                                            setOpen(true)
                                        }}
                                    />
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', mt: 5 }}>
                <Grid container alignItems="center" justifyContent="end" gap={1}>
                    <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(users.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                            shape="rounded"
                        />
                    </Stack>

                    <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                        <Select
                            value={rowsPerPage}
                            onChange={e => setRowsPerPage(+e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={5}>5 / page</MenuItem>
                            <MenuItem value={10}>10 / page</MenuItem>
                            <MenuItem value={25}>25 / page</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Box>
            <ModaleValidationDeblocageComponent
                openModale={open}
                setOpen={setOpen}
                message={`Voulez-vous lever le blocage du compte de ${selectedItem?.lastname} ${selectedItem?.firstname}`}
                handleValidate={() => {
                    if (selectedItem) liftUserSuspension()
                }}
            />
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </>
    )
}

export default AccountBlockingComponent
