import {
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    styled,
    tableCellClasses
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useEffect, useState } from 'react'
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import ModaleValidationComponent from '../../../shared/components/Modale/modale-validation.component'
import { Domaine, DomaineResponse } from '../../Domaine/interfaces/domaine.interface'
import domaineServices from '../../Domaine/services/domaine.service'
import { ApiResponse } from '../../../shared/interface/ApiResponse.interface'
import postServices from '../services/post.service'
import { toast } from 'react-toastify'
import { IPosteOccupe } from '../interfaces/post.interface'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: BASE_COLOR.WHITE,
        border: 0
    },
    '&:last-child td, &:last-child th': {
        border: 0
    },
    [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none'
    }
}))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: BASE_COLOR.CONTAINER,
        // fontSize: 15,
        fontWeight: 'bold'
        // textTransform: 'uppercase'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15
    }
}))

const PostComponent: React.FC<any> = () => {
    const [inputPost, setInputPost] = useState('')
    const [posts, setPosts] = useState<any[]>([])
    const [toUpdate, setToUpdate] = useState<any | null>(null)
    const [open, setOpen] = useState(false)
    const [modeConfirm, setModeConfirm] = useState<'deletion' | 'update' | null>(null)
    const [selectedItem, setSelectedItem] = useState<any | null>(null)
    const [selectedDomain, setSelectedDomain] = useState<Domaine | null>(null)
    const [domains, setDomains] = useState<Domaine[]>([])
    const [globalLoading, setGlobalLoading] = useState(false)
    const [loadingPosts, setLoadingPosts] = useState(false)
    const [reload, setReload] = useState(0)

    useEffect(() => {
        setGlobalLoading(true)
        domaineServices
            .get()
            .then((e: ApiResponse<DomaineResponse>) => {
                setDomains(e.data.domaines)
            })
            .catch(console.log)
            .finally(() => setGlobalLoading(false))
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchPosts()
    }, [selectedDomain, reload])

    const createPost = (name: string, domaine?: Domaine['_id']) => {
        if (domaine) {
            postServices
                .create(name, domaine)
                .then(() => {
                    setReload(prev => ++prev)
                    toast.success('Poste créé avec succès.')
                    setInputPost('')
                })
                .catch(console.log)
        }
    }

    const updateOne = (id: IPosteOccupe['_id']) => {
        postServices
            .update(inputPost, id)
            .then(() => {
                setReload(prev => ++prev)
                toast.success('Poste modifié avec succès')
            })
            .catch(console.log)
    }

    const deletePost = (id: IPosteOccupe['_id']) => {
        postServices
            .delete(id)
            .then(() => {
                toast.success('Poste supprimé avec succès')
                setReload(prev => ++prev)
            })
            .catch(console.log)
    }

    const fetchPosts = () => {
        if (selectedDomain) {
            setLoadingPosts(true)
            postServices
                .getPostsByDomaine(selectedDomain._id)
                .then(res => setPosts(res.data.data))
                .catch(console.log)
                .finally(() => setLoadingPosts(false))
        }
    }

    const selectADomain = (id: Domaine['_id']) => {
        const domain = domains.find(elt => elt._id === id)
        setSelectedDomain(domain ?? null)
    }

    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState<string>('fullName')

    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        const newOrder = isAsc ? 'desc' : 'asc'
        setOrder(newOrder)
        setOrderBy(property)

        const sorted = [...posts].sort((a, b) => (newOrder === 'asc' ? handleSortBy(a, b, property) : handleSortBy(b, a, property)))

        setPosts(sorted)
    }

    const handleSortBy = (a: Domaine, b: Domaine, property: string) => {
        return (a[property] ?? '').localeCompare(b[property])
    }

    return (
        <>
            <FormControl sx={{ m: 2, minWidth: '50%' }} size="small">
                <InputLabel id="domaine-selection-label">Choisissez un domaine</InputLabel>
                <Select
                    labelId="domaine-selection-label"
                    id="domaine-selection"
                    value={selectedDomain?.name}
                    label="Choisissez un domaine"
                    onChange={e => selectADomain(e.target.value)}
                >
                    {domains.map(domain => (
                        <MenuItem value={domain._id}>{domain.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {!loadingPosts ? (
                <>
                    <div className="pl-5 py-3 w-full grid grid-cols-3 gap-4">
                        <Input placeholder="Poste" onChange={e => setInputPost(e.target.value)} fullWidth />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (selectedDomain) createPost(inputPost, selectedDomain._id)
                            }}
                            disabled={selectedDomain === null || inputPost === ''}
                            //sx={{ backgroundColor: BASE_COLOR.PRIMARY }}
                        >
                            Ajouter un poste
                        </Button>
                    </div>
                    <TableContainer component="div" sx={{ maxHeight: 500 }} className="pl-5">
                        <Table
                            stickyHeader
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: 'none'
                                }
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeader sortDirection={orderBy === 'name' ? order : false}>
                                        <TableSortLabel
                                            active={true}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={() => handleSort('name')}
                                        >
                                            Poste(s)
                                        </TableSortLabel>
                                    </StyledTableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {posts &&
                                    posts.length > 0 &&
                                    posts.map((row: any, i: number) => (
                                        <StyledTableRow key={i}>
                                            <TableCell>
                                                {row._id === toUpdate ? (
                                                    <>
                                                        <TextField
                                                            value={inputPost}
                                                            label={"Edition d'un poste"}
                                                            error={inputPost === ''}
                                                            id="outlined-start-adornment"
                                                            onChange={(e: any) => setInputPost(e.target.value)}
                                                            variant="outlined"
                                                            sx={{ m: 1, width: '25ch' }}
                                                            helperText={inputPost === '' ? 'Ce champ ne peut être vide' : ''}
                                                        />
                                                    </>
                                                ) : (
                                                    <>{row.name}</>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {toUpdate === row._id ? (
                                                    <Grid container alignItems={'center'} gap={1} sx={{ justifyContent: 'end' }}>
                                                        <CheckCircleOutline
                                                            onClick={() => {
                                                                updateOne(row._id)
                                                                setToUpdate(null)
                                                            }}
                                                        />
                                                        <CancelOutlined onClick={() => setToUpdate(null)} />
                                                    </Grid>
                                                ) : (
                                                    <Grid container alignItems={'center'} gap={1} sx={{ justifyContent: 'end' }}>
                                                        <EditIcon
                                                            color="primary"
                                                            onClick={() => {
                                                                setToUpdate(row._id)
                                                                setInputPost(row.name)
                                                            }}
                                                        />
                                                        <DeleteIcon
                                                            color="error"
                                                            onClick={() => {
                                                                setOpen(true)
                                                                setModeConfirm('deletion')
                                                                setSelectedItem(row._id)
                                                            }}
                                                        />
                                                    </Grid>
                                                )}
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* <Box sx={{ display: 'flex', mt: 5 }}>
                 <Grid container alignItems="center" justifyContent="end" gap={1}>
                    <Stack spacing={2}>
                        <Pagination
 
                            count={10}
 
                            onChange={() => {}}
                            color="primary"
                            shape="rounded"  
                        />
                    </Stack>

                    <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                        <Select value={itemParPage} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem value={'5'}>5 / page</MenuItem>
                            <MenuItem value={'10'}>10 / page</MenuItem>
                            <MenuItem value={'25'}>25 / page</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> 
            </Box> */}
                    <ModaleValidationComponent
                        openModale={open}
                        setOpen={setOpen}
                        message={`Voulez-vous supprimer ce poste`}
                        handleValidate={() => {
                            if (modeConfirm) {
                                if (modeConfirm === 'deletion') {
                                    deletePost(selectedItem)
                                } else {
                                    updateOne(toUpdate)
                                }
                            }
                        }}
                    />
                </>
            ) : (
                <CircularProgress />
            )}
            <Backdrop open={globalLoading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </>
    )
}
export default PostComponent
