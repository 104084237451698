import { Dashboard } from '../interfaces/dashboard.interface'
import { DashboardActionTypes, DashboardActions } from './dashboard.action'

interface DashboardState {
    dashboards: Dashboard[]
    dashboardLoading: boolean
}

const initialState: DashboardState = {
    dashboards: [],
    dashboardLoading: false
}

const dashboardReducer = (state: DashboardState = initialState, action: DashboardActions): DashboardState => {
    switch (action.type) {
        case DashboardActionTypes.GET_DASHBOARD:
            return {
                ...state,
                dashboards: action.dashboard
            }
        default:
            return state
    }
}

export default dashboardReducer
