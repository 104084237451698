import { createStore, applyMiddleware } from 'redux'
import appReducer, { AppState } from './App.reducer'
import thunk from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/es/storage'

const config = {
    key: 'root',
    storage
}
const persisteReducer = persistReducer<AppState, any>(config, appReducer)

const store = createStore(persisteReducer, applyMiddleware(thunk))

const persistedStore = persistStore(store)

// export default store

export { store, persistedStore }
