import { useState, useRef, useEffect } from 'react'
import statistiqueServices from '../services/statistiques.service'
import { StatistiqueGeneraleResponse } from '../interfaces'
import dayjs, { Dayjs } from 'dayjs'

interface ValuePercentage {
    operator: string
    value: number
}

interface ColorStats {
    vert: ValuePercentage
    orange: ValuePercentage
    rouge: ValuePercentage
    [key: string]: ValuePercentage
}

const initializeValue: ColorStats = {
    vert: {
        operator: '=',
        value: 0
    },
    orange: {
        operator: '<',
        value: 90
    },
    rouge: {
        operator: '>',
        value: 50
    }
}
const useStatistiques = () => {
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(dayjs().startOf('month')))
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(dayjs().endOf('month')))

    const [stateColorStats, setStateColorStats] = useState<ColorStats>(initializeValue)
    const [statistiques, setStatistiques] = useState<StatistiqueGeneraleResponse[]>([])

    const [activePath, setActivePath] = useState<SVGPathElement | null>(null)
    const [isModalDisplayed, setIsModalDisplayed] = useState(false)
    const [stats, setStats] = useState<StatistiqueGeneraleResponse>()
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [departmentName, setDepartmentName] = useState<string | null>(null)
    const [colorAfterSelected, setColorAfterSelected] = useState<string | null>(null)

    const statTxtRef0 = useRef<SVGTextElement | null>(null)
    const statTxtRef1 = useRef<SVGTextElement | null>(null)
    const statTxtRef2 = useRef<SVGTextElement | null>(null)
    const statTxtRef3 = useRef<SVGTextElement | null>(null)
    const statTxtRef4 = useRef<SVGTextElement | null>(null)
    const statTxtRef5 = useRef<SVGTextElement | null>(null)
    const statTxtRef6 = useRef<SVGTextElement | null>(null)
    const modalGroupRef = useRef<SVGGElement | null>(null)

    const convertStateToHeaders = () => {
        return {
            colorVert: {
                label: stateColorStats.vert.operator,
                value: stateColorStats.vert.value
            },
            colorJaune: {
                label: stateColorStats.orange.operator,
                value: stateColorStats.orange.value
            },
            colorRouge: {
                label: stateColorStats.rouge.operator,
                value: stateColorStats.rouge.value
            },
            beginDate: startDate?.toDate() ?? null,
            endDate: endDate?.toDate() ?? null
        }
    }

    useEffect(() => {
        statistiqueServices.getStatistiqueGenerale(convertStateToHeaders()).then(response => setStatistiques(response.data.data))
    }, [])

    useEffect(() => {
        statistiqueServices.getStatistiqueGenerale(convertStateToHeaders()).then(response => setStatistiques(response.data.data))
    }, [stateColorStats, startDate, endDate])

    const getStatsByNumDepartement = (numDepartement: string | number) => statistiques.find(el => `${el.num_dep}` === `${numDepartement}`)

    const getColorByNumDepartement = (numDepartement: string | number, isClicked?: boolean) => {
        const statsFind = getStatsByNumDepartement(numDepartement)
        if (statsFind) {
            return getColorForMissionPerdu(statsFind.nombreMissionPerdu)
        }
        return ''
    }

    const getColorForMissionPerdu = (value: number) => {
        if (value === 0) return 'green'
        else if (value < 50) return 'orange'
        return 'red'
    }

    const handleMapClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        const { target } = event
        if (!isModalDisplayed) {
            if (target instanceof SVGPathElement) {
                setActivePath(target)
                setIsModalDisplayed(true)
                setOpenModal(true)
                if (target.getAttribute('fill')) {
                    setColorAfterSelected(target.getAttribute('fill'))
                    target.setAttribute('fill', 'blue')
                }
                if (target.getAttribute('data-nom')) setDepartmentName(target.getAttribute('data-nom'))
                const numeroDepartement = target.getAttribute('data-numerodepartement')
                if (numeroDepartement) {
                    const statsFind = getStatsByNumDepartement(numeroDepartement)
                    setStats(statsFind)
                    target.setAttribute('fill', 'blue')
                }
            }
        } else if (
            target instanceof HTMLElement &&
            target.parentNode instanceof HTMLElement &&
            target.parentNode.classList.contains('modal')
        ) {
            activePath!.classList.remove('active')
            setIsModalDisplayed(false)
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setIsModalDisplayed(false)
        if (activePath && activePath.getAttribute('fill')) {
            setColorAfterSelected(activePath.getAttribute('fill'))
            if (colorAfterSelected !== null) activePath.setAttribute('fill', colorAfterSelected)
        }
    }
    return {
        statistiques,
        getColorByNumDepartement,
        handleMapClick,
        statTxtRef0,
        statTxtRef1,
        statTxtRef2,
        statTxtRef3,
        statTxtRef4,
        statTxtRef5,
        statTxtRef6,
        handleCloseModal,
        modalGroupRef,
        stateColorStats,
        setStateColorStats,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        stats,
        openModal,
        departmentName
    }
}

export default useStatistiques
