import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Notification } from '../interfaces/notification.interface'
import notificationService from '../services/notification.service'
import { Backdrop, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import { DateFormater } from '../../../shared/functions/date'
import GetMissionLabel from '../../../shared/functions/mission-label'

const NotificationDetails: React.FC<any> = () => {
    const { id } = useParams()
    const [notification, setNotification] = useState<Notification | null>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (id) {
            setLoading(true)
            notificationService
                .getById(id)
                .then(res => {
                    setNotification(res.data.data)
                })
                .catch(console.log)
                .finally(() => setLoading(false))
        }
        //eslint-disable-next-line
    }, [])

    if (notification)
        return (
            <>
                {!loading && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {notification.mission && (
                                <Card className="w-screen" sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" fontWeight={'bold'}>
                                            Info de la mission
                                        </Typography>
                                        <div className="grid grid-cols-2">
                                            <div>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Domaine :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.mission.domaine.name}
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Adresse :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.mission.localisation.address}
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Jours :
                                                    <div className="grid grid-cols">
                                                        {notification.mission.jours.map(jour => (
                                                            <div>{DateFormater(String(jour.date))}</div>
                                                        ))}
                                                    </div>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Priorité :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.mission.priorite ? 'Oui' : 'Non'}
                                                    </Typography>
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Nombre de préstataires :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.mission.nombrePresta}
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Type de mission :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {GetMissionLabel(notification.mission.typeMission)?.label ?? ''}
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Coût :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.mission.cout} €
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    EPI :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.mission.epi}
                                                    </Typography>
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <CardContent>
                                <Typography variant="h6" component="div" fontWeight={'bold'}>
                                    Info du préstataire :
                                </Typography>
                                <Typography variant="h6" component="div" fontWeight={'bold'}>
                                    Info du créateur :
                                </Typography>
                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                    Nom et prénoms :
                                    <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                        {notification.prestataire.firstname} {notification.prestataire.lastname}
                                    </Typography>
                                </Typography>
                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                    Numéro :
                                    <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                        {notification.prestataire.numero}
                                    </Typography>
                                </Typography>
                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                    Ville :
                                    <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                        {notification.prestataire.city}
                                    </Typography>
                                </Typography>
                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                    Type de mission :
                                    <Typography sx={{ fontSize: 13 }} component={'span'} color="text.secondary" gutterBottom>
                                        {GetMissionLabel(notification.prestataire.typeMission)?.label ?? ''}
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid item xs={12}>
                            {notification.createur && (
                                <Card className="w-screen" sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div" fontWeight={'bold'}>
                                            Info du créateur :
                                        </Typography>
                                        <div className="grid grid-cols-2">
                                            <div>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Nom et prénoms :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.createur.firstname} {notification.createur.lastname}
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Numéro :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.createur.numero}
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Ville :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.createur.city}
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Nom de l'entreprise :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.createur.idEntreprise?.nomEntreprise ?? ''}
                                                    </Typography>
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Tva
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.createur.tva}
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Code ape :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.createur.codeApe}
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Chiffre d'affaire :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.createur.chiffreDaffaire} €
                                                    </Typography>
                                                </Typography>
                                                <Typography sx={{ fontSize: 13 }} fontWeight={'bold'}>
                                                    Siret entreprise :
                                                    <Typography
                                                        sx={{ fontSize: 13 }}
                                                        component={'span'}
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {notification.createur.siretEntreprise}
                                                    </Typography>
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                    </Grid>
                )}
                <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                    <CircularProgress />
                </Backdrop>
            </>
        )

    return <></>
}

export default NotificationDetails
