import { combineReducers } from 'redux'
import dashboardReducer from '../pages/Dashboard/store/dashboard.reducer'
import authReducer from '../pages/Auth/store/auth.reducer'
import utilisateurReducer from '../pages/Utilisateur/store/utilisateur.reducer'
import domaineReducer from '../pages/Domaine/store/domaine.reducer'

const appReducer = combineReducers({
    dashboard: dashboardReducer,
    auth: authReducer,
    utilisateur: utilisateurReducer,
    domaine: domaineReducer
})

export type AppState = ReturnType<typeof appReducer>

export default appReducer
