import { UtilisateurPrestataire } from '../interfaces/utilisateur.interface'
import { UtilisateurActionTypes, UtilisateurActions } from './utilisateur.action'

interface UtilisateurState {
    prestataires: UtilisateurPrestataire[]
    utilisateurLoading: boolean
}

const initialState: UtilisateurState = {
    prestataires: [],
    utilisateurLoading: false
}

const utilisateurReducer = (state: UtilisateurState = initialState, action: UtilisateurActions): UtilisateurState => {
    switch (action.type) {
        case UtilisateurActionTypes.GET_UTILISATEUR_PRESTATAIRE:
            return {
                ...state,
                prestataires: action.prestataire
            }
        default:
            return state
    }
}

export default utilisateurReducer
