import { ReactNode, useEffect, useState } from 'react'
import { IDomaines, User } from '../../../pages/Utilisateur/interfaces/utilisateur.interface'
import { FormControl, Grid, Input, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material'
import domaineServices from '../../../pages/Domaine/services/domaine.service'
import { ApiResponse } from '../../interface/ApiResponse.interface'
import { DomaineResponse } from '../../../pages/Domaine/interfaces/domaine.interface'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { toast } from 'react-toastify'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneIcon from '@mui/icons-material/Phone'
import MoneyIcon from '@mui/icons-material/Money'
import NumbersIcon from '@mui/icons-material/Numbers'
import PercentIcon from '@mui/icons-material/Percent'

export default function FilterUser(props: FilterUserProps) {
    const [domaineOption, setDomaineOption] = useState<IDomaines[]>([])
    const [loading, setLoading] = useState(false)
    const { onChangeUser, user } = props
    const [startDate, setStartDate] = useState<Dayjs | null>(null)
    const [endDate, setEndDate] = useState<Dayjs | null>(null)

    useEffect(() => {
        setLoading(true)
        domaineServices
            .get()
            .then((res: ApiResponse<DomaineResponse>) => setDomaineOption(res.data.domaines))
            .catch(console.log)
            .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [])
    // toast.error('Veuillez vérifier votre date de fin')
    const onChange = (name: string, value: any) => {
        if (name === 'startDate' && value && !endDate) {
            onChangeUser &&
                onChangeUser({
                    ...user,
                    startDate: value,
                    endDate: dayjs()
                })
            setStartDate(value)
            setEndDate(dayjs())
        } else if (name === 'endDate' && value && startDate && dayjs(value).isBefore(dayjs(startDate))) {
            setEndDate(dayjs())
            toast.error('Veuillez vérifier votre date de fin')
        } else {
            onChangeUser &&
                onChangeUser({
                    ...user,
                    [name]: value
                })
        }
    }

    return (
        <div className="flex flex-col">
            {loading ? (
                <Skeleton variant="rectangular" width={440} className="py-4" />
            ) : (
                <Grid container spacing={2} padding={5} direction={'row'}>
                    <Grid item xs={12} lg={12}>
                        <FormControl variant="outlined" sx={{ minWidth: 250 }}>
                            <InputLabel id="demo-simple-select-standard-label">Domaine</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={props.domaine?._id}
                                onChange={e => props.onChangeDomaine(domaineOption.find(domaine => domaine._id === e.target.value))}
                                label="Domaine"
                            >
                                <MenuItem value={''}>
                                    <em>Aucun</em>
                                </MenuItem>
                                {domaineOption.map(domaine => (
                                    <MenuItem value={domaine._id}>{domaine.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Nom"
                            value={user?.name}
                            variant="outlined"
                            onChange={e => onChange('name', e.currentTarget.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                )
                            }}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="E-mail"
                            value={user?.email}
                            variant="outlined"
                            onChange={e => onChange('email', e.currentTarget.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <MailOutlineIcon />
                                    </InputAdornment>
                                )
                            }}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Téléphone"
                            value={user?.numero}
                            variant="outlined"
                            onChange={e => onChange('numero', e.currentTarget.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PhoneIcon />
                                    </InputAdornment>
                                )
                            }}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="TVA"
                            value={user?.tva}
                            variant="outlined"
                            onChange={e => onChange('tva', e.currentTarget.value)}
                            type="number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PercentIcon />
                                    </InputAdornment>
                                )
                            }}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Code APE"
                            value={user?.apeCode}
                            variant="outlined"
                            onChange={e => onChange('apeCode', e.currentTarget.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <NumbersIcon />
                                    </InputAdornment>
                                )
                            }}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Chiffre d'affaire"
                            value={user?.chiffreDaffaire}
                            variant="outlined"
                            type="number"
                            onChange={e => onChange('chiffreDaffaire', e.currentTarget.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <MoneyIcon />
                                    </InputAdornment>
                                )
                            }}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            value={startDate}
                            onChange={newValue => onChange('startDate', newValue)}
                            label={'Date du'}
                            sx={{ width: 250 }}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker  minDate={startDate}  value={endDate} onChange={newValue => onChange('endDate', newValue)} label={'au'} sx={{ width: 250 }} />
                    </Grid>
                </Grid>
            )}
            {props.children}
        </div>
    )
}

export interface FilterUserProps {
    children: ReactNode
    domaine?: IDomaines
    onChangeDomaine: (domaine?: IDomaines) => void
    user?: any
    onChangeUser?: (user: any) => void
    name?: string
    onChangeName: (name: string) => void
}
