import httpCommon from '../../../shared/services/http-common'
import { Mission } from '../interfaces/missions.interface'

class MissionServices {
    getAll(page: number, limit: number) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/mission/${page}/${limit}`)
    }

    getOne(id: Mission['_id']) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/mission/get-one/${id}`)
    }

    deleteOne(id: Mission['_id']) {
        return httpCommon.delete(`${process.env.REACT_APP_API_ROOT}/mission/${id}`)
    }
}

const missionService = new MissionServices()

export default missionService
