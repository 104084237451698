import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ProtectedRoute from './protectedRoutes'
import AuthContainer from '../pages/Auth/containers/auth.container'
import PrestataireListContainer from '../pages/Utilisateur/containers/utilisateur-prestataire.container'
import UtilisateurCreateurComponent from '../pages/Utilisateur/components/utilisateur-createur.component'
import UtilisateursRolesComponent from '../pages/Utilisateur/components/utilisateur-roles.component'
import { useSelector } from 'react-redux'
import { selectAuth, selectTokenInfo } from '../pages/Auth/store/auth.selector'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ResetPasswordComponent from '../pages/Auth/components/auth-reset-password.component'
import NewPasswordComponent from '../pages/Auth/components/auth-new-password.component'
import PagenotFoundComponent from '../shared/components/PageNotFound/page-not-found.component'
import EditPasswordComponent from '../pages/EditPassword/components/edit-password.component'
import UtilisateurDetailsComponent from '../pages/Utilisateur/components/utilisateur-details'
import DomaineContainer from '../pages/Domaine/containers/domaine.container'
import UtilisateurCreateurMultiComponent from '../pages/Utilisateur/components/utilisateur-createur-multi-compte.component'
import MissionsListComponent from '../pages/Missions/components/missions-list.component'
import MissionDetails from '../pages/Missions/components/mission-details.component'
import PostComponent from '../pages/Post/components/post.component'
import FeesComponent from '../pages/Fees/components/fees.component'
import NotificationComponent from '../pages/Notification/components/notification.component'
import NotificationDetails from '../pages/Notification/components/notification-details.component'
import PaymentsComponent from '../pages/Payments/components/Payments'
import PaymentDetailsComponent from '../pages/Payments/components/payment-details.component'
import AccountBlockingComponent from '../pages/AccountBlocking/components/account-blocking.component'
import CounterDisplayComponent from '../pages/GeneralCounter/components/counter-display.component'
import StatistiquesComponents from '../pages/Statistiques/components/statistiques.component'
import UtilisateurAdminComponent from '../pages/Utilisateur/components/utilisateur-admins.components'
import AboutComponent from '../pages/About/AboutComponent'

const AppRouter = () => {
    const tokenInfo = useSelector(selectTokenInfo)
    const user = useSelector(selectAuth)
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<AuthContainer />} />
                    <Route path="/mot-de-passe-oublier" element={<ResetPasswordComponent />} />
                    <Route path="/noveau-mot-de-passe" element={<NewPasswordComponent />} />
                    <Route element={<ProtectedRoute isAllowed={tokenInfo && user} />}>
                        {/* <Route path="/" element={<DashboardContainer />} /> */}
                        <Route path="/statistiques" element={<StatistiquesComponents />} />
                        <Route path="/" element={<StatistiquesComponents />} />
                        <Route path="/utilisateur/prestataire" element={<PrestataireListContainer />} />
                        <Route path="/utilisateur/prestataire/:id" element={<UtilisateurDetailsComponent />} />
                        <Route path="/utilisateur/createur" element={<UtilisateurCreateurComponent />} />
                        <Route path="/utilisateur/createur/:id" element={<UtilisateurDetailsComponent />} />
                        <Route path="/utilisateur/createur-multi-compte" element={<UtilisateurCreateurMultiComponent />} />
                        <Route path="/utilisateur/createur-multi-compte/:id" element={<UtilisateurDetailsComponent />} />
                        <Route
                            path="/utilisateur/createur-multi-compte/sous-compte/:idParent/:idSousCompte"
                            element={<UtilisateurDetailsComponent />}
                        />
                        <Route path="/utilisateur/roles" element={<UtilisateursRolesComponent />} />
                        <Route path="/utilisateur/admins" element={<UtilisateurAdminComponent />} />
                        <Route path="/mission" element={<MissionsListComponent />} />
                        <Route path="/mission/:id" element={<MissionDetails />} />
                        <Route path="/account-blocking" element={<AccountBlockingComponent />} />
                        <Route path="/notification" element={<NotificationComponent />} />
                        <Route path="/notification/:id" element={<NotificationDetails />} />
                        <Route path="/payments" element={<PaymentsComponent />} />
                        <Route path="/payments/:id" element={<PaymentDetailsComponent />} />
                        <Route path="/general-counter" element={<CounterDisplayComponent />} />
                        <Route path="/about" element={<AboutComponent />} />
                        <Route path="/parametre/domaine" element={<DomaineContainer />} />
                        <Route path="/parametre/posts" element={<PostComponent />} />
                        <Route path="/parametre/fees" element={<FeesComponent />} />
                        <Route path="/modification-mot-de-passe" element={<EditPasswordComponent />} />
                        <Route path="*" element={<PagenotFoundComponent />} />
                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer position={'bottom-right'} />
        </>
    )
}

export default AppRouter
