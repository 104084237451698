import { VisibilityOff, Visibility } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'
import monImage from '../../../shared/images/logo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { selectEmailTemp } from '../store/auth.selector'
import { getAuthAction, setEmailRecoverAccountAction, setTokenInfoAction } from '../store/auth.action'
import authServices from '../services/auth.service'
import { LoginResponse, resetPassword } from '../interfaces/auth.interfaces'
import { ApiResponse } from '../../../shared/interface/ApiResponse.interface'

const NewPasswordComponent: React.FC<any> = () => {
    const navigate = useNavigate()
    const disptach = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [passwordValidator, setPasswordValidator] = useState<boolean>(true)
    const [confirmPasswordValidator, setConfirmPasswordValidator] = useState<boolean>(true)
    const handleClickShowPassword = () => setShowPassword(show => !show)
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show)
    const passwordValidationRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}$/

    const passwordIsValide = password.length >= 8 //&& password.trim() !== ''
    const passwordConfirmIsValide = confirmPassword.length >= 8 //&& confirmPassword.trim() !== ''

    const [code, setCode] = useState<number | null>(null)
    const [codeValidator, setCodeValidator] = useState<boolean>(true)
    const email = useSelector(selectEmailTemp)
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }
    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const checkStrongPassword = (password: string): boolean => {
        return passwordValidationRegex.test(password)
    }

    const checkStrongPasswordMessage = (password: string, validator: boolean): string => {
        if (password.length < 8 && !validator) return '8 caractères minimum'
        if (password.length > 0) return passwordValidationRegex.test(password) ? 'le mot de passe est fort' : 'le mot de passe est faible'
        return ''
    }

    const colorTextHelperMessage = (password: string, validator: boolean): any => {
        let style = {
            helper: {
                color: 'red'
            }
        }
        if (password.length < 8 && !validator) {
            return style
        } else if (passwordValidationRegex.test(password)) {
            style.helper.color = '#2e7d32'
        } else {
            style.helper.color = '#ed6c02'
        }
        return style
    }

    const loginNewPassword = () => {
        if (!code) setCodeValidator(false)
        if (!passwordIsValide) setPasswordValidator(false)
        if (!passwordConfirmIsValide) setConfirmPasswordValidator(false)

        if (password === confirmPassword && passwordIsValide && passwordConfirmIsValide && code !== 0 && code !== null) {
            setCodeValidator(true)
            setPasswordValidator(true)
            setConfirmPasswordValidator(true)
            if (!email) {
                toast.warning('Veuiller identifer votre email')
                navigate('/mot-de-passe-oublier')
            } else {
                const params: resetPassword = {
                    recoveryCode: code,
                    password: password,
                    passwordConfirm: confirmPassword
                }
                authServices
                    .resetPassword(params)
                    .then((response: ApiResponse<LoginResponse>) => {
                        if (response.data.success) {
                            disptach(setEmailRecoverAccountAction(null)) //delete emailtemp in store
                            disptach(setTokenInfoAction(response.data))
                            disptach(getAuthAction(response.data.user))
                            navigate('/utilisateur/prestataire')
                            toast.success(response.data.message)
                            toast.success('Connexion Reussie')
                        } else {
                            toast.error(`${response.data.message}`)
                        }
                    })
                    .catch(error => {
                        toast.error(error.message)
                    })
            }
        } else {
            toast.error('Les mots de passe doivent être identiques')
        }
    }

    return (
        <div className="w-screen h-screen bg-primary flex flex-col justify-center items-center gap-16">
            <div className="bg-white rounded-[64px] p-5 h-44 w-44 bg-opacity-0">
                <img src={monImage} alt="logo" className=" h-40" />
            </div>
            <div className="bg-white rounded-lg">
                <div className="mx-[45px] my-[32px] flex flex-col gap-3">
                    <TextField
                        label={`Code de validation`}
                        error={!codeValidator}
                        type="number"
                        id="outlined-adornment-code"
                        onChange={(e: any) => {
                            if (!isNaN(e.target.value)) setCode(e.target.value)
                        }}
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        helperText={!codeValidator ? 'code nécessaire' : ''}
                    />
                    <TextField
                        label={`Nouveau Mot de passe`}
                        error={!passwordValidator}
                        color={password.length ? (checkStrongPassword(password) ? 'success' : 'warning') : 'primary'}
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e: any) => {
                            setPassword(e.target.value)
                        }}
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        helperText={checkStrongPasswordMessage(password, passwordValidator)}
                        FormHelperTextProps={{ style: colorTextHelperMessage(password, passwordValidator)?.helper }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        label={`Confirmation mot de passe`}
                        error={!confirmPasswordValidator}
                        color={confirmPassword.length ? (checkStrongPassword(confirmPassword) ? 'success' : 'warning') : 'primary'}
                        id="outlined-adornment-confirm-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        onChange={(e: any) => {
                            setConfirmPassword(e.target.value)
                        }}
                        variant="standard"
                        sx={{ m: 1, width: '25ch' }}
                        helperText={checkStrongPasswordMessage(confirmPassword, confirmPasswordValidator)}
                        FormHelperTextProps={{ style: colorTextHelperMessage(confirmPassword, confirmPasswordValidator)?.helper }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownConfirmPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <div className="flex justify-center w-full">
                        <Button
                            variant="contained"
                            sx={{ borderRadius: '20px' }}
                            onClick={loginNewPassword}
                            className="w-2/3 !bg-[#0B7D75]"
                        >
                            Valider
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewPasswordComponent
