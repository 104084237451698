import { Action } from 'redux'
import { Dashboard } from '../interfaces/dashboard.interface'

export enum DashboardActionTypes {
    GET_DASHBOARD = '[Dashboard] get Dashboard list'
}

/**
 * get Dashboard value
 */
export interface GetDashboardAction extends Action<DashboardActionTypes.GET_DASHBOARD> {
    dashboard: Dashboard[]
}

export const getDashboardAction = (dashboard: Dashboard[]): GetDashboardAction => ({
    type: DashboardActionTypes.GET_DASHBOARD,
    dashboard
})

export type DashboardActions = GetDashboardAction
