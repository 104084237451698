import { Action } from 'redux'
import { Domaine } from '../interfaces/domaine.interface'

export enum DomaineActionTypes {
    SET_DOMAINES = '[Domaine] get Domaines list',
    ADD_DOMAINE = '[Domaine] add Domaines'
}

/**
 * set Domaine liste
 */
export interface SetDomaineAction extends Action<DomaineActionTypes.SET_DOMAINES> {
    domaines: Domaine[]
}

export const setDomaineAction = (domaines: Domaine[]): SetDomaineAction => ({
    type: DomaineActionTypes.SET_DOMAINES,
    domaines
})

/**
 * add Domaine
 */
export interface AddDomaineAction extends Action<DomaineActionTypes.ADD_DOMAINE> {
    domaine: Domaine
}

export const addDomaineAction = (domaine: Domaine): AddDomaineAction => ({
    type: DomaineActionTypes.ADD_DOMAINE,
    domaine
})

export type DomaineActions = SetDomaineAction | AddDomaineAction
