export const CreateMissionTypeEnum = [
    {
        _id: '1',
        value: 'MissionForce1',
        name: 'Mission Force1',
        description: 'sans charge à soulever'
    },
    {
        _id: '2',
        value: 'MissionForce2',
        name: 'Mission Force2',
        description: 'Potentiellement des charges légères à déplacer (<20kg)'
    },
    {
        _id: '3',
        value: 'MissionForce3',
        name: 'Mission Force3',
        description: 'Nécessite de déplacer des charges lourdes (>20kg)'
    }
]
