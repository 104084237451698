import { useEffect, useState } from 'react'
import theme from 'tailwindcss/defaultTheme'

export function useMobileBreakPoint() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= parseInt(theme.screens.sm, 10))

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= parseInt(theme.screens.sm, 10))
        }
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return { isMobile }
}
