import { connect } from 'react-redux'
import { AppState } from '../../../store/App.reducer'
import { selectAuth } from '../store/auth.selector'

import { getAuthAction } from '../store/auth.action'
import LoginComponent from '../components/auth.component'

type AuthMappedProps = ReturnType<typeof mapStateToProps>
type AuthDispatchedProps = typeof mapDisptachToProps

export type AuthInjectedProps = AuthMappedProps & AuthDispatchedProps

const mapStateToProps = (state: AppState) => ({
    user: selectAuth(state)
})

const mapDisptachToProps = {
    loadAuths: getAuthAction
}

const AuthContainer = connect(mapStateToProps, mapDisptachToProps)(LoginComponent)

export default AuthContainer
