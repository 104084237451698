export const enum DayDisponibilty {
    LUNDI = 'lundi',
    MARDI = 'mardi',
    MERCREDI = 'mercredi',
    JEUDI = 'jeudi',
    VENDREDI = 'vendredi',
    SAMEDI = 'samedi',
    DIMANCHE = 'dimanche'
}

export const enum HoursDisponibilty {
    MATIN = 'matin',
    APRESMIDI = 'apres_midi',
    SOIR = 'soir',
    JOURNE = 'journe',
    NUIT = 'nuit',
    ALL = 'all',
    INDISPONIBLE = 'indisponible'
}

export const DayDispo = (val: string) => {
    let v = 'Toute la journée'
    if (val === HoursDisponibilty.JOURNE) {
        v = 'Toute la journée'
    } else if (val === HoursDisponibilty.NUIT) {
        v = 'La nuit'
    } else if (val === HoursDisponibilty.SOIR) {
        v = 'La soirée'
    } else if (val === HoursDisponibilty.APRESMIDI) {
        v = 'Après-midi'
    } else if (val === HoursDisponibilty.ALL) {
        v = '24h/24'
    } else if (val === HoursDisponibilty.MATIN) {
        v = 'Le matin'
    } else {
        v = 'Je suis indisponible'
    }
    return v
}

export const dispo = [
    {
        label: 'Les Lundis',
        value: DayDisponibilty.LUNDI
    },
    {
        label: 'Les Mardis',
        value: DayDisponibilty.MARDI
    },
    {
        label: 'Les Mercredis',
        value: DayDisponibilty.MERCREDI
    },
    {
        label: 'Les Jeudis',
        value: DayDisponibilty.JEUDI
    },
    {
        label: 'Les Vendredis',
        value: DayDisponibilty.VENDREDI
    },
    {
        label: 'Les Samedis',
        value: DayDisponibilty.SAMEDI
    },
    {
        label: 'Les Dimanches',
        value: DayDisponibilty.DIMANCHE
    }
]
