import { CreateMissionTypeEnum } from '../constants/mission-type.constant'

export default function GetMissionLabel(mission: string) {
    const value = CreateMissionTypeEnum.find(m => m.value === mission)
    if (!value) return undefined
    return {
        label: value.name,
        description: value.description
    }
}
