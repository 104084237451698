import { Typography } from '@mui/material'

const AboutComponent: React.FC<any> = () => {
    return (
        <div >
            <Typography style={{fontSize:30,marginLeft:30}}>Howonk version 1.0.0</Typography> 
        </div>
    )
}

export default AboutComponent
