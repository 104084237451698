import httpCommon from '../../../shared/services/http-common'
import { User } from '../../Utilisateur/interfaces/utilisateur.interface'

class AccountBlockingServices {
    getAllblockedAccount(page: number, limit: number) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/suspended-account/${page}/${limit}`)
    }
    getAllblockedUserAccount(page: number, limit: number) {
        return httpCommon.get(`${process.env.REACT_APP_API_ROOT}/users/getall-suspended/${page}/${limit}`)
    }

    liftSuspension(id: User['_id']) {
        return httpCommon.put(`${process.env.REACT_APP_API_ROOT}/users/lift-suspension/${id}`)
    }
}

const accountBlockingServices = new AccountBlockingServices()

export default accountBlockingServices
