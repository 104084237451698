import { Backdrop, Button, Card, CircularProgress, Input } from '@mui/material'
import { useEffect, useState } from 'react'
import feesServices from '../services/fees.services'
import { toast } from 'react-toastify'
import { HourlyCost } from '../interfaces/fees.interfaces'

const FeesComponent: React.FC<any> = () => {
    const [inputFees, setInputFees] = useState<number | null>(null)
    const [hourlyCost, setHourlyCost] = useState<HourlyCost | null>(null)
    const [loading, setLoading] = useState(false)
    const [dirtyInputFees, setDirtyInputFees] = useState(false)
    const [dirtyInputHourlyCost, setDirtyInputHourlyCost] = useState(false)
    const [originFees, setOriginFees] = useState<number | null>(null)
    useEffect(() => {
        setLoading(true)
        Promise.all([feesServices.getFees(), feesServices.getHourlyCost()])
            .then(([fees, hourly]) => {
                setInputFees(fees.data.data.value)
                setOriginFees(fees.data.data.value)
                setHourlyCost(hourly.data.data)
            })
            .catch(console.log)
            .finally(() => setLoading(false))
    }, [])

    const onModifyFees = () => {
        if (inputFees) {
            setLoading(true)
            feesServices
                .updateFees(inputFees)
                .then(() => {
                    toast.success('Frais modifiés avec succès')
                    setDirtyInputFees(false)
                    setOriginFees(inputFees)
                })
                .catch(console.log)
                .finally(() => setLoading(false))
        }
    }

    const onModifyHourlyCost = () => {
        if (hourlyCost) {
            setLoading(true)
            feesServices
                .updateHourlyCost(hourlyCost)
                .then(() => {
                    toast.success('Coût horaire modifié avec succès')
                    setDirtyInputHourlyCost(false)
                })
                .finally(() => setLoading(false))
        }
    }
    // const onFileChange =async (e:any) => {
    //     console.log("ooooooo",e.target.files[0])
    //     const formData = new FormData()
    //     // formData.append('profileImg', e.target.files[0])
    //     console.log("ooooooo formData",formData)
    //     formData.append('pieceJustificatif', e.target.files[0])
    //     console.log('formData ******', formData)
    //     let res = await fetch(`https://api-howonk-test.ingenosya.mg/users/upload`, {
    //         method: 'PUT',
    //         body: formData
    //     })
    //     console.log('re ****************** okoko', res)
    // }
    return (
        <div>
            <Card className="px-5 py-3 my-5 w-full grid grid-cols-5 gap-4">
                <div className="col-span-2">
                    <Input
                        type="number"
                        placeholder="Frais de la plateforme"
                        value={inputFees}
                        onChange={e => {
                            setDirtyInputFees(true)
                            setInputFees(+e.target.value)
                        }}
                        fullWidth
                        inputProps={{ min: 1 }}
                    />
                </div>
                <div>%</div>
                <div className="col-span-2">
                    <Button variant="contained" color="primary" onClick={onModifyFees} disabled={inputFees === null || !dirtyInputFees || originFees === inputFees}>
                        Modifier les frais de la plateforme
                    </Button>
                </div>
                {/* <div>
                    <input type="file" onChange={onFileChange} />
                </div> */}
            </Card>
            {/* <Card className="px-5 py-3 my-5 w-full grid grid-cols-3 gap-4">
                <Input
                    type="number"
                    placeholder="Valeur de coût horaire"
                    value={hourlyCost?.value ?? 0}
                    onChange={e => {
                        setDirtyInputHourlyCost(true)
                        setHourlyCost(previous => (previous ? { ...previous, value: +e.target.value } : null))
                        // setHourlyCost(prev => return {value: +e.target.value, ...prev})
                    }}
                    fullWidth
                    inputProps={{ min: 1 }}
                />
                <Input
                    placeholder="Label de coût horaire"
                    value={hourlyCost?.label ?? ''}
                    onChange={e => {
                        setDirtyInputHourlyCost(true)
                        setHourlyCost(previous => (previous ? { ...previous, label: e.target.value } : null))
                    }}
                    fullWidth
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onModifyHourlyCost}
                    disabled={hourlyCost === null || !dirtyInputHourlyCost}
                >
                    Modifier le coût horaire
                </Button>
            </Card> */}
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </div>
    )
}

export default FeesComponent
